<template>
  <SwitchWithSlot
    :value.sync="patient.authorized_person"
    label="Osoba upoważniona do dokumentacji medycznej."
    :autosave="autosave"
    :syncId="syncId"
  >
    <v-layout column v-if="patient.authorized_person">
      <DefaultSubtitle class="mx-4">
        Dane osoby upoważnionej do dokumentacji medycznej
      </DefaultSubtitle>
      <v-layout wrap class="mt-6">
        <v-flex xs12 sm6>
          <LabelTextField
            :value.sync="patient.relatedpersons[relatedPersonIndex].first_name"
            label="Imię"
            :autosave="autosave"
            :syncId="syncId"
            clearable
          ></LabelTextField>
        </v-flex>
        <v-flex xs12 sm6>
          <LabelTextField
            :value.sync="patient.relatedpersons[relatedPersonIndex].last_name"
            label="Nazwisko"
            :autosave="autosave"
            :syncId="syncId"
            clearable
          ></LabelTextField>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm6>
          <LabelTextField
            :value.sync="patient.relatedpersons[relatedPersonIndex].pesel"
            label="PESEL"
            :rules="nonRequiredPESEL"
            :autosave="autosave"
            :syncId="syncId"
            clearable
          ></LabelTextField>
        </v-flex>
        <v-flex xs12 sm6>
          <LabelTextField
            :value.sync="patient.relatedpersons[relatedPersonIndex].phone"
            label="Numer kontaktowy"
            :rules="nonRequiredPhoneRules"
            :autosave="autosave"
            :syncId="syncId"
            clearable
          ></LabelTextField>
        </v-flex>
      </v-layout>
      <LabelTextField
        :value.sync="patient.relatedpersons[relatedPersonIndex].address"
        label="Miejsce zamieszkania"
        :autosave="autosave"
        :syncId="syncId"
        clearable
        hide-details
      ></LabelTextField>
    </v-layout>
  </SwitchWithSlot>
</template>

<script>
import Validators from "@/mixins/Validators";
import RelatedPersonMixin from "@/mixins/RelatedPersonMixin";
import ProxyModificationMixin from "@/mixins/ProxyModificationMixin"

export default {
  mixins: [Validators, RelatedPersonMixin, ProxyModificationMixin],
  props: {
    patient: {},
  },
  data() {
    return {
      type: 1,
      autoFillAddress: true,
      autoFillCondition: "authorized_person",
    };
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    SwitchWithSlot: () => import("@/components/controls/SwitchWithSlot"),
    LabelTextField: () => import("@/components/LabelTextField"),
  },
};
</script>